:root {
    --highlight-color-dark: rgb(0, 51, 255);
    --highlight-color-light: rgb(25, 83, 255);
    --highlight-green-light: rgb(68, 197, 43); 
    --highlight-green-dark: rgb(43, 161, 41); 
    --highlight-teal-light: turquoise; 
    --highlight-teal-dark: teal; 
}
* {
  padding: 0;
  margin: 0;
}

body {
  font-size: 13 px;
  font-style: normal;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#tabSelectorContainer{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

#logoutContainer {
    display:flex;
    align-items: center;
    justify-content: right;
    width: 30%;
}

#logo {
  margin-right: 20px;
}

#titleContainer {
  display:flex;
  align-items: center;
  justify-content: left;
  width: 30%;
}


#itemAddContainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: right;
    margin: 10px 0 20px 0;
}

input {
  height: 30px;
  font-size: 13px;
}

#amountInput{
  width: 80px;
}

#datePicker{
  width: 100px;
}

tr:hover .editButton{
    display: block;
}

td{
    border: 1px solid black;
    padding:5px;
    height: 100%;
}

.editButton {
    display: none;
    margin-left: -10px;
    padding: 0 20px 0 20px;
    height: 15px;
    width: auto;
    cursor: pointer;
}


.editTransaction {
    width: 16px;
    border: none;
}

table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 60%;
}

#tableContainer {
    display:flex;
    flex-flow: column wrap;
    align-content: center;
}

/* CSS */
.logout {
  align-items: center;
  background-image: linear-gradient(135deg, rgb(0, 51, 255) 40%, rgb(25, 83, 255));
  background-image: linear-gradient(135deg, var(--highlight-color-dark) 40%, var(--highlight-color-light));
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: "Codec cold",sans-serif;
  font-size: 16px;
  font-weight: 700;
  height: 50px;
  width: 100px;
  justify-content: center;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  touch-action: manipulation;
}

.logout span {
  transition: all 200ms;
}

.logout:hover span {
  -webkit-transform: scale(.9);
          transform: scale(.9);
  opacity: .75;
}


/* CSS */
.styledButton {
  align-items: center;
  background-clip: padding-box;
  background-color: rgb(230, 230, 230);
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  margin: 10px;
  width: 15%;
}

.styledButton:hover {
  background-color: rgb(25, 83, 255);
  background-color: var(--highlight-color-light);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.styledButton.active {
  background-color: rgb(0, 51, 255);
  background-color: var(--highlight-color-dark);
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  color: white;
}

.addTransaction {
  background-color: teal;
  background-color: var(--highlight-teal-dark);
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  transition: all 250ms;
  margin: 5px;
  padding: 5px;
  

}

.addTransaction:hover{
  background-color: turquoise;
  background-color: var(--highlight-teal-light);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  color:black;
}

#balanceContainer {
  margin: auto;
  margin-top: 25px;
  width: 150px;
  text-align: center;
  background-color: green;
  padding: 15px;
  border-radius: 10px;
  box-shadow: black 2px 2px 5px;
  font-weight: 700;
}

#pageSelector {
  margin-top: 15px;
  margin-bottom: 15px;
  display:flex;
  justify-content: center;
}

.pageButton{
  font-size: large;
  margin: 0 10px 0 10px;
  padding: 0 5px 0 5px;
  border: 1px solid rgb(0, 51, 255);
  border: 1px solid var(--highlight-color-dark);
  color: rgb(0, 51, 255);
  color: var(--highlight-color-dark);
  border-radius: 5px;
  cursor: pointer;
  transition: all 150ms;
}

.pageButton:hover{
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media only screen and (max-width: 600px) {
  #title{
    display:none;
  }

  #logoutContainer, #titleContainer{
    width: 20%;
  }

  .styledButton {
    width: 20%;
  }

  table{
    width: 90%;
  }

}
